
import './App.css';
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
// import { Switch, Route, Link, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Main from './components/main';
import Layout from "./components/Layout";
import Unauthorized from "./components/Unauthorized";
// import { toast, Toaster } from "react-hot-toast";
import RequireAuth from './components/RequireAuth';



function App() {

  // var vConsolePlugin = require('vconsole-webpack-plugin'); 
  return (


    <div>
      <div>

        <Routes>
          <Route path='/' element={< Main />}>

            {/* Public Route */}
            <Route path='login' element={< Login />} />
            <Route path='unauthorized' element={< Unauthorized />} />

            {/* Protecetd Route */}
            <Route element={< RequireAuth />}>
              <Route path='home' element={< Main />} />
            </Route>

            <Route path='*' element={< Navigate to='/login' />} />

          </Route>

        </Routes>
      </div>
    </div>

  );
}

export default App;
