
// import axios from 'axios';
import { toast, Toaster } from "react-hot-toast";
const MODUL_MQTT_NAME = "thing"
export const log = function (msg) {
    var fieldList = document.getElementById("logs");
    var li = document.createElement("li");
    li.innerText = msg;
    fieldList.appendChild(li);
  };

  // change the name to login
export const handleFlightRequest = async (flightRequest) => {
    
    toast.error("Flight Request Not supported yet!");
    return null;
    const cred = await fetch('/cred');
    const credJson = await cred.json();
    
    const APP_ID = credJson.APP_ID_PLACEHOLDER;
    const APP_KEY = credJson.APP_KEY_PLACEHOLDER;
    const LICENSE = credJson.LICENSE_PLACEHOLDER;
    log("Debug: " + APP_ID)
    
    const token = window.djiBridge.platformVerifyLicense(APP_ID, APP_KEY, LICENSE);
    log("DEBUG: token" + token)
    log("platform is verified: " + window.djiBridge.platformIsVerified());
    const register_params = (await fetch('/mqtt')).json();
    log("DEBUG3 APP_ID: " + register_params);
    var LoadComponent = window.djiBridge.platformLoadComponent(MODUL_MQTT_NAME, register_params);
    var connect_status = window.djiBridge.thingGetConnectState();
    log("Load Component: thing " + LoadComponent);
    log("State: " + connect_status);
    // need to think how to provide the callback function
    window.djiBridge.thingConnect(register_params.username, register_params.password, "reg_calback"); 
    log("Thing Connect state: " + window.djiBridge.thingGetConnectState());
    window.djiBridge.platformIsComponentLoaded(MODUL_MQTT_NAME)


    // axios.get('cred/')
    // console.log("handleFlightRequest: ", flightRequest);
    // return axios.post('http://localhost:8080/flightRequest', flightRequest)
    //     .then(response => {
    //         console.log("response: ", response);
    //         return response;
    //     })
    //     .catch(error => {
    //         console.log("error: ", error);
    //         return error;
    //     });  
    return null;
}