import  { useLocation, Navigate, Outlet} from 'react-router-dom';
import  useAuth  from '../hooks/useAuth';


const RequireAuth = () => {
    const { auth } = useAuth();
    const location = useLocation();

    if (auth) {
        return <Outlet />;
    } else {
        return <Navigate to={{ pathname: "/login", state: { from: location } }} />;
    }   
}


export default RequireAuth;