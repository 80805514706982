import React, { useEffect, useState } from 'react';
import { handleFlightRequest, log } from '../utils/handleFlightRequest.js'
import './Login.css';
// import { djiutils } from '../utils/djiUtils.js';
import { toast, Toaster } from "react-hot-toast";
import my_axios from '../api/axios.js';
import axios from '../api/axios.js';
import { json } from 'react-router-dom';
// import { wait } from '@testing-library/user-event/dist/utils/index.js';
// import  reg_callback_1  from '../hooks/djicallback.js';

const djiBridge = window.djiBridge;

// window.djiBridge.onStopPlatform = () => {
//   // 自定义业务需求
//   return true
// }







const Main = () => {


  const [urlData, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [isVerified, setIsVerified] = useState(false);
  const [isPlatformLoaded, setIsPlatformLoaded] = useState(false);
  const [isThingLoaded, setIsThingLoaded] = useState(false);
  const [isLiveShareLoaded, setIsLiveShareLoaded] = useState(false);
  const [isLiveShareStarted, setIsLiveShareStarted] = useState(false);
  const [isLiveShareStopped, setIsLiveShareStopped] = useState(false);


  const [videoShareText, setVideoShareText] = useState("Start video share");



  const buttonStyle = {
    margin: '0 10px',
    padding: '10px 15px',
    marginTop: '20px',
    fontSize: '16px',
    borderRadius: '5px',
    cursor: 'pointer',
    backgroundColor: '#3498db',
    color: '#fff',
    border: 'none',
    flex: '1',
  };

  // defind buttonStyle of disabled button
  const disabledButtonStyle = {
    margin: '0 10px',
    padding: '10px 15px',
    marginTop: '20px',
    fontSize: '16px',
    borderRadius: '5px',
    cursor: 'pointer',
    backgroundColor: '#3498db',
    color: '#fff',
    border: 'none',
    flex: '1',
    opacity: '0.5',
  };



  useEffect(() => {

    
    if (djiBridge !== undefined && djiBridge !== null) {
      checkplatform();

    }
    else {
      toast.error("DJI Bridge not loaded!");
    }





  }, [  ]);

  const checkplatform = () => {

    var platformName = process.env.REACT_APP_PLATFORM_NAME;
    var workspaceName = process.env.REACT_APP_PLATFORM_WORKSPACE;
    var desc = process.env.REACT_APP_PLATFORM_NAME;
    var isVerifiedRes = djiBridge.platformIsVerified();

    if (isVerified) {
      toast.success("Platform is alredy verified!");
      setIsVerified(true);
      
      window.djiBridge.onBackClick = () => { toast.success("Press the phiscal back button to exit!"); return true; };
    }
    else {
  
      // const the_toast = toast.loading("Verify licence...", { duration: 10000 });
      my_axios.get('/cred')
        .then((cred) => {
  
          const credJson = cred?.data;
  
          const APP_ID = credJson.APP_ID_PLACEHOLDER;
          const APP_KEY = credJson.APP_KEY_PLACEHOLDER;
          const LICENSE = credJson.LICENSE_PLACEHOLDER;
          const token = djiBridge?.platformVerifyLicense(APP_ID, APP_KEY, LICENSE);
  
          window.djiBridge.platformSetWorkspaceId("18c71152-c552-42e7-b094-f510ff44e9cb");
          window.djiBridge.platformSetInformation(platformName, workspaceName, desc);
  
          window.djiBridge.onBackClick = () => {
            toast.success("Press the phiscal back button to exit!");
            return true;
          };
  
          var loading_mqtt_toast = toast.loading("Connecting to MQTT...", { duration: 10000 });
  
          var param = JSON.stringify({
            host: process.env.REACT_APP_MQTT_URL,
            connectCallback: "thing_callback",
            username: "admin",
            password: "public"
          });
          window.djiBridge.platformLoadComponent("thing", param);
          window.djiBridge.thingConnect("admin", "public", "thing_callback");
          var thingStatet = window.djiBridge.thingGetConnectState();
  
          
  
  
          // try to connect in a loop 5 times with 1 second delay
          var i = 0;
          while (JSON.parse(thingStatet).code !== 0 && i < 50) {
            
            thingStatet = window.djiBridge.thingGetConnectState();
            i++;
            
  
          }
          if (JSON.parse(thingStatet).code === 0) {
            toast.success("MQTT connected!");
            setIsPlatformLoaded(true);
            
          }
          else {
            toast.error("MQTT not connected!");
          }
  
  
          
          toast.dismiss(loading_mqtt_toast);
  
  
  
  
          if (token) {
  
            toast.success("License verified!");
            setIsVerified(true);
          } else {
            toast.error("License not verified!");
          }
  
        }).catch((err) => {
          log("message " + err.message);
          toast.error("Backend not responding!", { duration: 10000 });
        });

  
    }
  
  }


  const Status = () => {
    const sleep_time = 10000;


    log("thing platformIsComponentLoaded " + djiBridge?.platformIsComponentLoaded("thing"), { duration: sleep_time });
    log("liveshare status " + djiBridge?.platformIsComponentLoaded("liveshare"), { duration: sleep_time });


    log(
      "Status platformIsComponentLoaded: " +
      djiBridge?.platformIsComponentLoaded("thing") +
      "\nthing state" +
      djiBridge?.thingGetConnectState()
    );

    log("Status liveshare Loaded: " + window.djiBridge.liveshareGetStatus());
    log("liveshare Loaded " + djiBridge?.platformIsComponentLoaded("liveshare"));
  };






  const logOut = () => {

    toast.success("Logging out!");
    setIsVerified(false);
    djiBridge?.platformUnloadComponent("thing");
    djiBridge?.platformUnloadComponent("liveshare");
    djiBridge.liveshareStopLive();
    djiBridge?.platformStopSelf();
  };


  const startVideoShare = () => {

    

    if (videoShareText === "Stop video share") {
      djiBridge.liveshareStopLive();
      toast.success("Video share stopped!");
      // djiBridge.platformUnloadComponent("liveshare");
      setVideoShareText("Start video share");
      return;
    }
    setVideoShareText("Stop video share");

    var liveshare_param = JSON.stringify({ videoPublishType: 'video-by-manual', statusCallback: "liveshare_callback" });
    var res1 = window.djiBridge.platformLoadComponent("liveshare", liveshare_param);
    var parameter = window.djiBridge.liveshareGetConfig();

    // check if res1.code is 0
    if (JSON.parse(res1).code !== 0) {
      toast.error("Error loading liveshare component!");
      setVideoShareText("Start video share");
      // make a get call to /stop_video with the drone sn as parameter
      var droneSN = djiBridge?.platformGetAircraftSN();
      axios.get('/stop_video', { droneSN: JSON.parse(droneSN).data });

      return;
    }

    

    // get remote control sn
    var remoteSN = djiBridge?.platformGetRemoteControllerSN();

    // get drone sn
    var droneSN = djiBridge?.platformGetAircraftSN();

    // get rtmp url 
    // fetch(/rtmp_url, data: {remoteSN: remoteSN})


    axios.post('/get_rtmp_url', { droneSN: JSON.parse(droneSN).data })
      .then(response => {
      
        var params = JSON.stringify({
          url: response.data.url
        });
        //Type 0:Unknow，1：Agora，2：RTMP，3：RTSP，4：GB28181
        window.djiBridge.liveshareSetConfig(2, params);
        window.djiBridge.liveshareGetStatus();
        window.djiBridge.liveshareStartLive();

      })
      .catch(err => {
        setError(err);
        toast.error("Error getting rtmp url!");
        setVideoShareText("Start video share");
      })
      .finally(() => {
        
        setLoading(false);
      });

      toast.success("Video share started!");
  };


  const clearLogs = () => {
    document.getElementById("logs").innerHTML = "";
  }




  return (
    <div>
      <div>
        <Toaster toastOptions={{ duration: 2000 }} />
      </div>
      <div >
        <button style={buttonStyle} disabled={!isPlatformLoaded} onClick={startVideoShare}>{videoShareText}</button>
        
        <button style={buttonStyle} onClick={logOut}>Logout</button>
        <button style={buttonStyle} onClick={handleFlightRequest}>Request Flight</button>
        <button style={buttonStyle} onClick={Status}>Logs</button>
        <button style={buttonStyle} onClick={clearLogs}>Clear Logs</button>

      </div>

      
    </div>
  );
};

export default Main;
