

// simple unauthorized component show only h1 Unauthorized

const Unauthorized = () => {
    return (
        <div>
        <h1>Unauthorized</h1>
        </div>
    );
    }

export default Unauthorized;


