import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './Login.css';
import { toast, Toaster } from "react-hot-toast";
import logo from '../media/logo_white_new.png';
import useAuth from '../hooks/useAuth';



const LOGIN_URL = '/auth'
// const LoginPage = () => {
function LoginPage() {
  // State to manage input values
  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/home";


  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [buttonClass, setButtonClass] = useState('submit-btn');

  // Function to handle form submission
  const handleLogin = (e) => {
    e.preventDefault();
    // You can add your authentication logic here
    console.debug('Username:', username);
    console.log('Password:', password);
    if (true || username.toLowerCase() === 'admin' && password.toLowerCase() === 'admin') {
      // toast.success("Login successful!");
      const role = 0;
      const accessToken = "accessToken";
      setAuth({ username, password, role, accessToken });
      setUsername('');
      setPassword('');
      navigate(from, { replace: true });

    }

    else if (username.toLowerCase() !== 'admin' || password.toLowerCase() !== 'admin') {
      toast.error("Wrong username or password!");
    }
    // if the username is empty
    else {
      if (username === '') {
        toast.error("Username cannot be empty!");
      }
      // if the password is empty
      if (password === '') {
        toast.error("Password cannot be empty!");
      }
    }
    // Add your authentication logic here (e.g., send data to a server)
  };

  const handleMouseOver = (e) => {
    if (e.type === "mouseover") {
      { setButtonClass("submit2-btn") };
    }
    else {
      { setButtonClass("submit-btn") };
    }


  };

  return (
    <div>

      <div className="flex-container">

        <div className="content-container">
          <div className="form-container">
            <Toaster toastOptions={{ duration: 4000 }} />
            <img src={logo} height={50} ></img>

            <form onSubmit={handleLogin}>
              <label>

                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="Login_Login_Input__JpXig"
                  placeholder='Username'
                />
              </label>
              {/* <br /> */}
              <label>

                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="Login_Login_Input__JpXig"
                  placeholder='Password'
                />
              </label>
              <br />
              <button className={buttonClass} onMouseOver={handleMouseOver} onMouseLeave={handleMouseOver} type="submit">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
