import axios from 'axios';
// look at https://www.youtube.com/watch?v=nI8PYZNFtac&ab_channel=DaveGray
// https://www.youtube.com/watch?v=X3qyxo_UTR4&ab_channel=DaveGray
export default axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      'Access-Control-Allow-Origin' : '*',

        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Access-Control-Max-Age': '300'
     
  }
}
);